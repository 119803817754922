module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-190910775-1","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/favicon.ico","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5d00515231228a15e24a57f64089da90"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx"],"defaultLayouts":{},"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Users/mccantuta/Dev/Src/mccantuta/personal-website","commonmark":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/Users/mccantuta/Dev/Src/mccantuta/personal-website/node_modules/@bonobolabs/gatsby-remark-images-custom-widths","id":"584f96b2-6aad-5ba1-8b1d-8ef8d46c17dd","name":"@bonobolabs/gatsby-remark-images-custom-widths","version":"0.0.6","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":["onRouteUpdate"],"ssrAPIs":["onRenderBody"]}],"defaultLayouts":{"default":"/Users/mccantuta/Dev/Src/mccantuta/personal-website/src/components/layout.js"},"gatsbyRemarkPlugins":[{"resolve":"/Users/mccantuta/Dev/Src/mccantuta/personal-website/node_modules/@bonobolabs/gatsby-remark-images-custom-widths","id":"584f96b2-6aad-5ba1-8b1d-8ef8d46c17dd","name":"@bonobolabs/gatsby-remark-images-custom-widths","version":"0.0.6","modulePath":"/Users/mccantuta/Dev/Src/mccantuta/personal-website/node_modules/@bonobolabs/gatsby-remark-images-custom-widths/index.js","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":["onRouteUpdate"],"ssrAPIs":["onRenderBody"]}],"extensions":[".mdx"],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Users/mccantuta/Dev/Src/mccantuta/personal-website","commonmark":false},
    },{
      plugin: require('../node_modules/@bonobolabs/gatsby-remark-images-custom-widths/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@bonobolabs/gatsby-remark-images-custom-widths/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-material-ui-top-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":""},
    }]
